<template>
  <b-tabs
    vertical
    content-class="col-12 col-md-9 mt-1 mt-md-0"
    pills
    nav-wrapper-class="col-md-3 col-12"
    nav-class="nav-left"
  >

    <!-- general tab -->
    <b-tab active>

      <!-- title -->
      <template #title>
        <feather-icon
          icon="UserIcon"
          size="18"
          class="mr-50"
        />
        <span class="font-weight-bold">General</span>
      </template>

      <account-setting-general
        v-if="options.general"
        :general-data="options.general"
      />
    </b-tab>
    <!--/ general tab -->

    <!-- change password tab -->
    <b-tab>

      <!-- title -->
      <template #title>
        <feather-icon
          icon="LockIcon"
          size="18"
          class="mr-50"
        />
        <span class="font-weight-bold">Change Password</span>
      </template>

      <account-setting-password />
    </b-tab>
    <!--/ change password tab -->

    <!-- info -->
    <b-tab>

      <!-- title -->
      <template #title>
        <feather-icon
          icon="InfoIcon"
          size="18"
          class="mr-50"
        />
        <span class="font-weight-bold">Information</span>
      </template>

      <account-setting-information
        v-if="options.info"
        :information-data="options.info"
      />
    </b-tab>

    <!-- social links -->
    <b-tab>

      <!-- title -->
      <template #title>
        <feather-icon
          icon="LinkIcon"
          size="18"
          class="mr-50"
        />
        <span class="font-weight-bold">Social</span>
      </template>

      <account-setting-social
        v-if="options.social"
        :social-data="options.social"
      />
    </b-tab>

    <!-- notification -->
    <b-tab>

      <!-- title -->
      <template #title>
        <feather-icon
          icon="BellIcon"
          size="18"
          class="mr-50"
        />
        <span class="font-weight-bold">Notifications</span>
      </template>

      <account-setting-notification
        v-if="options.notification"
        :notification-data="options.notification"
      />
    </b-tab>
  </b-tabs>
</template>

<script>
import { BTabs, BTab } from 'bootstrap-vue'
import AccountSettingGeneral from './AccountSettingGeneral.vue'
import AccountSettingPassword from './AccountSettingPassword.vue'
import AccountSettingInformation from './AccountSettingInformation.vue'
import AccountSettingSocial from './AccountSettingSocial.vue'
import AccountSettingNotification from './AccountSettingNotification.vue'

export default {
  components: {
    BTabs,
    BTab,
    AccountSettingGeneral,
    AccountSettingPassword,
    AccountSettingInformation,
    AccountSettingSocial,
    AccountSettingNotification,
  },
  // data() {
  //   return {
  //     options: {},
  //   }
  // },
  // beforeCreate() {
  //   console.log('AccountSettings.vue - get the user options from the backend server')
  //   // this.$http.get('/account-setting/data').then(res => { this.options = res.data })
  //   const userData = localStorage.getItem('userData')
  //   console.log(userData)
  //   this.options.social = userData.socials
  //   this.options.notifications = userData.notifications
  // },

  setup() {
    const userData = JSON.parse(localStorage.getItem('userData'))
    // console.log('AccountSettings - userData')
    // console.log(userData)
    // console.log(userData.name)
    // console.log(userData.email)

    const options = {}
    options.social = userData.social
    options.notification = userData.notification

    // console.log('AccountSettings - add these to the UserModel on the backend or modify the view to remove')
    options.social.connections = {
      twitter: {},
      google: {},
      facebook: {},
      github: {},
    }

    options.general = {
      avatar: '@/assets/images/portrait/small/avatar-s-11.jpg',
      username: 'johndoe',
      fullName: 'John Doe',
      email: 'granger007@hogward.com',
      company: 'Crystal Technologies',
    }

    options.info = {
      bio: '',
      dob: null,
      country: 'USA',
      website: '',
      phone: 6562542568,
    }

    return {
      options,
    }
  },

}
</script>
